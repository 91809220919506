import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import { mobile, tablet, PageHeader, Headline, Subtitle } from '~styles/global'
import styled from '@emotion/styled'
import VideoPlayer from '~components/VideoPlayer'
import Section from '~components/Section'
import RichText from '~components/RichText'
import AccordionItem from '~components/AccordionItem'
import Image from '~components/Image'

const SustainabilityPage = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PageHeader>
				<Headline>{page.headline}</Headline>
				<Subtitle>{page.subtitle}</Subtitle>
			</PageHeader>
			<Section>
				{page?.video?.asset?.url ? 
					<Video video={page?.video?.asset?.url} placeholder={page?.videoPlaceholder}/>			
					:
					<>
						<MainImage image={page.imageFallback}/>
					</>
				}
			</Section>
			<Section>
				<Text>
					<RichText content={page?.text}/>
				</Text>
				<AccordionTitle>
					{page.accordionTitle}
				</AccordionTitle>
				<Accordion>
					{page?.accordion?.map(row => (
						<AccordionItem title={row.title} key={row.title} italicChildren>
							<RichText content={row.text}/>
						</AccordionItem>
					))}
				</Accordion>
			</Section>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Video = styled(VideoPlayer)`
	grid-column: 4/10;
	margin-bottom: var(--xxxl);
	border-radius: 10px;
	overflow: hidden;
	${tablet}{
		grid-column: span 12;
	}
`
const MainImage = styled(Image)`
	grid-column: 4/10;
	margin-bottom: var(--xxxl);
	border-radius: 10px;
	overflow: hidden;
	${tablet}{
		grid-column: span 12;
	}
`
const Text = styled.div`
	grid-column: 4/10;
	text-align: center;
	max-width: 475px;
  margin: 0 auto var(--xxxxl);
	${mobile}{
		grid-column: span 12;
	}
`
const AccordionTitle = styled.h6`
	text-align: center;
	grid-column: span 12;
	margin-bottom: var(--xl);
`
const Accordion = styled.div`
	grid-column: 4/10;
	border-bottom: 1px solid;
	margin-bottom: var(--xxxxl);
	${tablet}{
		grid-column: 2/12;
	}
	${mobile}{
		grid-column: span 12;
	}
`
SustainabilityPage.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query SustainabilityPageQuery($slug: String) {
    page: sanitySustainabilityPage(slug: {current: {eq: $slug}}) {
      title
			headline
			subtitle
			video {
				asset {
					url
				}
			}
			videoPlaceholder{
				asset {
					url
				}
			}
			imageFallback{
				...imageWithAlt
			}
			text: _rawText
			accordionTitle
			accordion {
				title
				text: _rawText
			}
			seo {
				...seo
			}
    }
  }
`

export default SustainabilityPage