import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Player, ControlBar, ProgressControl, BigPlayButton } from 'video-react'
import { useInView } from 'framer-motion'
import 'video-react/dist/video-react.css'
import {PlayIcon} from '~components/Svg'

const VideoPlayer = ({ className, video, placeholder }) => {

	const playerRef = useRef()
	const [playing, setPlaying] = useState(false)

	const play = () => {
		playerRef?.current?.actions?.play()
		setPlaying(true)
	}

	console.log(placeholder)

	return (
		<Wrap className={className}>
			<PlayOverlay onClick={() => play()} playing={playing}>
				<PlayButton />
			</PlayOverlay>
			<PlayerStyles className='h6'>
				<Player
					src={video}
					ref={playerRef}
					playsInline
					muted
					poster={placeholder?.asset?.url}
				/>
			</PlayerStyles>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
`
const PlayOverlay = styled.button`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: ${ props => props.playing ? 'none' : 'all'};
	opacity: ${ props => props.playing ? '0' : '1'};
	transition: opacity 0.25s;
	z-index: 1;
`
const PlayButton = styled(PlayIcon)`
	width: 102px;
	${mobile}{
		width: 50px;
	}
`
const PlayerStyles = styled.div`
	.video-react {
		font-family: inherit;
		letter-spacing: 0.05em;
		.video-react-big-play-button{
			display: none;
		}
		.video-react-play-progress{
			&:after{
				font-family: 'Halcom';
				padding: 8px 8px 7px 8px;
			}
		}
		.video-react-poster{
			background-size: cover;
		}
	}
`


VideoPlayer.propTypes = {
	className: PropTypes.string,
	video: PropTypes.object,
	placeholder: PropTypes.object,
}

export default VideoPlayer