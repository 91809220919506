import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Collapse } from 'react-collapse'
import { PlusIcon, MinusIcon } from '~components/Svg'

const AccordionItem = ({ className, title, children, italicChildren }) => {

	const [open, setOpen] = useState(false)

	return (
		<Wrap className={className}>
			<Header onClick={() => setOpen(!open)}>
				<div>{title}</div>
				<Icon>
					<Minus open={open}/>
					<Plus open={open}/>	
				</Icon>
			</Header>
			<Collapse isOpened={open}>
				<div>
					<Children italic={italicChildren}>
						{children}
					</Children>
				</div>
			</Collapse>
		</Wrap>
	)
}

const Wrap = styled.div`
	width: 100%;
`

const Children = styled.div`
	padding-bottom: var(--l);
	font-style: ${props => props.italic ? 'italic' : 'normal'}
`

const Plus = styled(PlusIcon)`
	margin-right: var(--xs);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: ${ props => props.open ? '0' : '1'};
`

const Minus = styled(MinusIcon)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: ${ props => props.open ? '1' : '0'};
`

const Header = styled.button`
	padding: var(--m) 0;
	border-top: 1px solid;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
`

const Icon = styled.div`
	width: 19px;
	height: 19px;
	position: relative;
	margin-right: 14px;
	${mobile}{
		margin-left: var(--s);
	}
`

AccordionItem.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.node,
}

export default AccordionItem